<template>
  <div class="text-shadow" style="overflow:auto;pointer-events:auto;display:inline-block;max-width:100%;">
    <ul class="list-unstyled text-shadow" style="white-space:nowrap;font-size:14px;margin:0;">
      <li v-for="(item,index) in groupItems" class="hotspotItem" style="display:inline-block;margin:5px;pointer-events:auto;vertical-align: top;position:relative;">
        <div>
          <div class="picback" :class="{'active':editItemGuid==item.guid}">
          </div>
          <div style="position:relative">
            <a @click="setEditItem(item.guid)" style="color:#fff;cursor:pointer;">
              <div style="text-align:center;font-size:0;margin: 2px;">
                <div class="HotspotIconView" style="width:50px;height:50px;background-color:#ccc;display: inline-block;background-position:center;background-size:cover" :style="makeUrlStyle((((item.linkItem||{}).firstImage||{url:''}).url||'').replace('/0/0/0/0/', '/200/0/0/0/'))">
                </div>
              </div>
              <div class="text-truncate" style="text-align:center;font-size:8px;position:absolute;bottom: 0;left: 2px;right: 2px;">
                {{item.title||'未命名'}}
              </div>
            </a>
            <div class="text-shadow" style="position:absolute;top:-3px;right:0;padding:3px 3px;pointer-events:none">
              <span class="badge badge-primary">{{getHotspots(item).length}}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {
      }
    },
    computed: {
      $v() {
        return this.publicData.$v
      },
      form() {
        return this.publicData.form || {}
      },
      groupGuid() {
        return this.publicData.groupGuid
      },
      groupItems() {
        return this.publicData.groupItems || []
      },
      editItemGuid() {
        return this.publicData.editItemGuid
      },
      editItemIndex() {
        for (var i in this.form.items) {
          if (this.form.items[i].guid == this.editItemGuid) {
            return i
          }
        }
        return null
      },
      editItem() {
        if (this.editItemIndex === null) {
          return {}
        }
        return this.form.items[this.editItemIndex]
      },
      senceGuid() {
        return this.publicData.senceGuid
      },
    },
    watch: {
    //  groupGuid(val, oldval) {
    //    if (val != oldval) {
    //      setTimeout(() => {
    //        if (this.groupItems && this.groupItems[0]) {
    //          this.getFunc('changePublicData')({ editItemGuid: this.groupItems[0].guid })
    //        }
    //      }, 1)
    //    }
    //  }
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          setEditItem: this.setEditItem
        },
        events: {
          showEditItemModel: {
            target: 'SenceSettingModel',
            name: 'show'
          },
          addItem: {
            target: 'SenceSettingModel',
            name: 'addItemStart'
          }
        }
      })
    },
    destroyed() {
    },
    methods: {
      getHotspots(item) {
        if (item) {
          return item.hotspots.filter((v) => v.type != 'Block')
        } else {
          if (this.editItem.hotspots) {
            return this.editItem.hotspots.filter((v) => v.type != 'Block')
          } else {
            return []
          }
        }
      },
      addItemStart(index, groupGuid) {
        this.$emit('addItem', { index, groupGuid })
      },
      inGroupMove(item, relative) {
        var ps = this.getInGroupIndexs(item.roomId)
        var from = this.getIndex(item.guid)
        var psi = 0
        for (var i = 0; i < ps.length; i++) {
          if (ps[i] == from) {
            psi = i
            break
          }
        }
        var targeti = psi + Number(relative)
        if (targeti >= 0 && targeti < ps.length) {
          this.moveItem(from, ps[targeti])
        } else {
          this.$message({
            message: '移动错误2',
            type: 'error',
            zIndex: 9999,
          })
          return
        }
      },
      getInGroupIndexs(groupGuid) {
        var indexs = []
        for (var i = 0; i < this.form.items.length; i++) {
          if (this.form.items[i].roomId == groupGuid) {
            indexs.push(i)
          }
        }
        return indexs
      },
      getIndex(guid) {
        for (var i in this.form.items) {
          if (this.form.items[i].guid == guid) {
            return i
          }
        }
        return false
      },
      moveItem(from, to) {
        var item = this.form.items[from]
        if (to < 0 || to > this.form.items.length || item == null) {
          this.$message({
            message: '移动错误',
            type: 'error',
            zIndex: 9999,
          })
          return
        }
        this.form.items.splice(from, 1)
        this.form.items.splice(to, 0, item)
        var groupItems = this.getFunc({ target: 'Data', name: 'getGroupItems' })(this.groupGuid)
        this.getFunc('changePublicData')({ groupItems })
      },
      setEditItem(guid) {
        if (!guid) return
        if (this.editItemGuid == guid) {
          this.$emit('showEditItemModel')
        }
        this.getFunc('changePublicData')({ editItemGuid: guid })
        //  this.$forceUpdate()
      },
      makeUrlStyle(url) {
        return {
          'background-image': "url('" + url + "')"
        }
      },
    },
  }
</script>
<style scoped>
  .tourGroupItems {
    white-space: nowrap;
    font-size: 14px;
    margin: 0;
    overflow: auto;
    opacity: 0.8;
    text-align: center;
    background-color: #0006;
  }

  .GroupItem {
    display: inline-block;
    margin: 5px;
    width: 4em;
    vertical-align: top;
  }

    .GroupItem.active {
      margin: 4px;
      border: 1px solid #fff;
      box-shadow: #000 0 0 2px 0;
    }

  .arrowbtn {
    background-color: transparent;
    border: 0;
    border-radius: 4px;
    color: #fff;
    outline: 0;
  }

  .picback {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 25px;
    background-color: #fffa;
    box-shadow: #0005 2px;
    box-shadow: #000 0 0 2px;
  }

    .picback.active {
      background-color: #ffbb00aa;
    }
</style>
